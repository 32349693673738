import { render, staticRenderFns } from "./BrandButtons.vue?vue&type=template&id=3c112293&scoped=true"
import script from "./BrandButtons.vue?vue&type=script&lang=js"
export * from "./BrandButtons.vue?vue&type=script&lang=js"
import style0 from "./BrandButtons.vue?vue&type=style&index=0&id=3c112293&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c112293",
  null
  
)

export default component.exports